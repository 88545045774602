import {
	HeadingMedium,
	ParagraphMedium,
	ParagraphSmall,
} from 'baseui/typography';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { getDatabase, ref, set } from 'firebase/database';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Button } from 'baseui/button';
import { ConfigContext } from '../../contexts/config/ConfigContext';
import { ContactRequest } from './ContactRequest';
import type { ContactRequestT } from '../../types';
import { FormControl } from 'baseui/form-control';
import { Icon } from 'baseui/icon';
import { Input } from 'baseui/input';
import { Link } from 'react-router-dom';
import { StyleContext } from '../../contexts/style/StyleContext';
import { StyledLink } from 'baseui/link';
import { getContactRequestForUserPath } from '../../utils';
import { getContactRequestPath } from '../../utils';
import { toaster } from 'baseui/toast';
import { useQueryArray } from '../../hooks/useQuery';
import { useStyletron } from 'baseui';

export function ContactPageGuest() {
	const [css] = useStyletron();
	const config = useContext(ConfigContext);
	const { isLightMode } = useContext(StyleContext);
	const { user } = useContext(AuthContext);
	const [text, setText] = useState('');
	const isGuest = !!user;
	const isLoggedOut = !user;
	const uid = user?.uid ?? '';
	const userName = user?.displayName ?? 'Me';
	const customerSupportName = config.customerSupportName;
	const db = getDatabase();

	const [contactRequests, setContactRequests] = useQueryArray<ContactRequestT>(
		getContactRequestForUserPath(uid)
	);
	const filteredAndSortedContactRequests = contactRequests.filter(
		(contactRequest) => contactRequest.state === 'open'
	);
	const userHasContactRequests = contactRequests.length > 0;

	const handleSubmit = useCallback(() => {
		const uid = user?.uid ?? '';
		const email = user?.email;
		const ts = Date.now();
		const contactRequest: ContactRequestT = {
			ts,
			uid,
			email,
			state: 'open',
			bookingId: null,
			messages: [{ text: text, uid: user?.uid, sender: 'user' }],
		};
		const dbPath = getContactRequestPath(uid, ts);
		set(ref(db, dbPath), contactRequest)
			.then(() => {
				setText('');
				toaster.positive('Request submitted successfully.');
				setContactRequests((contactRequests) => [
					contactRequest,
					...contactRequests,
				]);
			})
			.catch((error) =>
				toaster.negative(
					`Failed to submit your request. Error: ${JSON.stringify(error)}`
				)
			);
	}, [user?.uid, user?.email, text, db, setContactRequests]);

	const contactInfo = useMemo(
		() => (
			<>
				<ParagraphSmall>
					<div
						className={css({
							marginTop: '1rem',
							display: 'flex',
							alignItems: 'center',
						})}>
						<Icon title='phone'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill={isLightMode ? 'black' : 'white'}
								className='bi bi-phone'
								viewBox='0 0 16 16'>
								<path d='M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z' />
								<path d='M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
							</svg>
						</Icon>
						<div className={css({ marginLeft: '0.5rem' })}>
							{config.phoneNumber}
						</div>
					</div>
				</ParagraphSmall>
				<ParagraphSmall>
					<div
						className={css({
							marginTop: '1rem',
							display: 'flex',
							alignItems: 'center',
						})}>
						<Icon title='email'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill={isLightMode ? 'black' : 'white'}
								className='bi bi-mailbox'
								viewBox='0 0 16 16'>
								<path d='M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z' />
								<path d='M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z' />
							</svg>
						</Icon>
						<div className={css({ marginLeft: '0.5rem' })}>{config.email}</div>
					</div>
				</ParagraphSmall>
			</>
		),
		[config, css, isLightMode]
	);
	const loggedOutInfo = useMemo(
		() => (
			<>
				<div className={css({ paddingTop: '1rem', paddingBottom: '1rem' })}>
					<ParagraphMedium>
						You must be logged in to send a contact request
					</ParagraphMedium>
				</div>
				<ParagraphMedium>
					<Link to='/login' className='text-thm'>
						<StyledLink>Login</StyledLink>
					</Link>{' '}
					or{' '}
					<Link to='/register' className='text-thm'>
						<StyledLink>Register</StyledLink>
					</Link>
				</ParagraphMedium>
			</>
		),
		[css]
	);
	const guestInfo = useMemo(
		() => (
			<>
				{filteredAndSortedContactRequests.length === 0 && (
					<>
						<FormControl label='Request'>
							<Input
								value={text}
								onChange={(e) => setText(e.target.value)}
								type='textarea'
							/>
						</FormControl>
						<Button onClick={handleSubmit}>Send</Button>
					</>
				)}
				{userHasContactRequests && (
					<>
						{filteredAndSortedContactRequests.map((contactRequest) => (
							<ContactRequest
								contactRequest={contactRequest}
								userName={userName}
								customerSupportName={customerSupportName}
							/>
						))}
					</>
				)}
			</>
		),
		[
			customerSupportName,
			text,
			handleSubmit,
			userHasContactRequests,
			filteredAndSortedContactRequests,
			userName,
		]
	);

	return (
		<>
			<HeadingMedium>Contact Us</HeadingMedium>
			{contactInfo}
			{isLoggedOut && loggedOutInfo}
			{isGuest && guestInfo}
		</>
	);
}
