import React, { ReactNode, useContext } from 'react';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { StyleContext } from '../../contexts/style/StyleContext';
import { useStyletron } from 'baseui';

export function ScreenContainer({
	adminPage,
	guestPage,
}: {
	adminPage: ReactNode;
	guestPage: ReactNode;
}) {
	const [css, theme] = useStyletron();
	const { isLightMode } = useContext(StyleContext);
	const { isAdmin } = useContext(AuthContext);
	return (
		<div
			className={css({
				position: 'relative',
				minHeight: '100vh',
				background: isLightMode ? theme.colors.white : theme.colors.black,
			})}>
			<Navbar />
			<div
				className={css({
					display: 'flex',
					flexDirection: 'column',
					marginLeft: '10vw',
					marginRight: '10vw',
					justifyContent: 'center',
					alignItems: 'center',
					paddingTop: '2rem',
					paddingBottom: '8rem',
				})}>
				{isAdmin ? adminPage : guestPage}
			</div>
			<Footer />
		</div>
	);
}
