import { DarkTheme, LightTheme, Theme } from 'baseui';

import { KIND } from 'baseui/tag';
import type { ListingT } from './types';

export type ConfigT = {
	// Metadata
	appName: string;
	adminCopywrite: string;
	adminEmail: string;
	customerSupportName: string;
	description: string;
	bookingSearchCTA: string;
	keywords: Array<string>;
	faviconPath: string;
	// Contact
	phoneNumber: string;
	email: string;
	// Styling
	lightTheme: Theme;
	darkTheme: Theme;
	primaryColor: string;
	// Listings
	listings: Array<ListingT>;
	maxNumVisitors: number;
};

export const DEFAULT_CONFIG: ConfigT = {
	appName: 'App Name',
	adminCopywrite: '© 2023 Company Name',
	adminEmail: 'therealworldprojectllc@gmail.com',
	customerSupportName: 'Customer Support',
	description: 'Company Description',
	bookingSearchCTA: 'Book Your Escape',
	keywords: ['example', 'keywords'],
	faviconPath: 'images/favicon.ico',
	phoneNumber: '+1(123)456-7890',
	email: 'contact@company.com',
	lightTheme: LightTheme,
	darkTheme: DarkTheme,
	primaryColor: '#1778F2',
	maxNumVisitors: 10,
	listings: [],
};

export const REAL_WORLD_HOMES_CONFIG: ConfigT = {
	...DEFAULT_CONFIG,
	appName: 'Real World Homes',
	adminCopywrite: '', // '© 2023 The Real World Project LLC'
	adminEmail: 'admin@realworldhomes.com',
	description: 'Real homes rented by real people.',
	keywords: ['Real World Homes', 'rental'],
	faviconPath: 'images/real-world-homes-favicon.png',
	phoneNumber: '+1(307)400-8617',
	email: 'support@realworldhomes.com',
	maxNumVisitors: 10,
	listings: [
		{
			id: '1',
			name: 'Historical Hideaway',
			description:
				'Beautiful San Francisco apartment in the heart of the city.',
			imageUrls: [
				'images/61-Grace-2.png',
				'images/61-Grace-4.png',
				'images/61-Grace-3.png',
				'images/61-Grace-1.png',
			],
			address: '123 Easy Street SF, CA 94103',
			numBeds: 3,
			numBaths: 2,
			type: 'Apartment',
			price: 4800,
			paymentInterval: 'Month',
			tags: [
				{ message: 'Washer', kind: KIND.positive },
				{ message: 'Dryer', kind: KIND.positive },
				{ message: 'Rooftop', kind: KIND.positive },
				{ message: 'Skylight', kind: KIND.positive },
				{ message: 'Remodel', kind: KIND.blue },
			],
			squareFootage: '1142 Square Feet',
			maxNumVisitors: 6,
		},
	],
};

export const BLUESTEIN_PROPERTIES_CONFIG: ConfigT = {
	...DEFAULT_CONFIG,
	appName: 'Bluestein Properties',
	adminCopywrite: '© 2023 Bluestein Properties',
	adminEmail: 'admin@bluesteinproperties.com',
	faviconPath: 'images/bluestein-properties-favicon.png',
	phoneNumber: '+1(307)400-8617',
	email: 'support@bluesteinproperties.com',
};
