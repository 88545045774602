import { AppNavBar, NavItem } from 'baseui/app-nav-bar';
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Checkbox } from 'baseui/checkbox';
import { ConfigContext } from '../../contexts/config/ConfigContext';
import { Item } from 'baseui/menu';
import { LabelMedium } from 'baseui/typography';
import { StyleContext } from '../../contexts/style/StyleContext';
import { auth } from '../../firebaseSetup';
import { signOut } from 'firebase/auth';
import { toaster } from 'baseui/toast';

export function Navbar() {
	const navigate = useNavigate();
	const config = useContext(ConfigContext);
	const { user, isAdmin } = useContext(AuthContext);
	const { isDarkMode, toggleStyleMode } = useContext(StyleContext);
	const displayName = user?.displayName ?? '';
	const handleLogout = () =>
		signOut(auth)
			.then(() => toaster.info('Successfully logged out.'))
			.catch(() => toaster.negative('Failed to logout. Please try again.'));
	const styleModeItem: NavItem = {
		label: 'Dark Mode',
		info: { id: 'darkMode', url: null },
	};
	const guestMainItems: NavItem[] = [
		{ label: 'Contact', info: { id: 'contact', url: '/contact' } },
	];
	const adminMainItems: NavItem[] = [
		{ label: 'Listings', info: { id: 'listing', url: '/listing' } },
		{ label: 'Bookings', info: { id: 'book', url: '/book' } },
		{ label: 'Contact Requests', info: { id: 'contact', url: '/contact' } },
	];
	const guestUserOptions: NavItem[] = [
		{ label: 'Login', info: { id: 'login', url: '/login' } },
		{ label: 'Register', info: { id: 'register', url: '/register' } },
		styleModeItem,
	];
	const authUserOptions: NavItem[] = [
		{ label: 'Profile', info: { id: 'profile', url: '/profile' } },
		{
			label: 'Logout',
			info: {
				id: 'logout',
				url: '/',
				onClick: handleLogout,
			},
		},
		styleModeItem,
	];
	const userItems = !!user ? authUserOptions : guestUserOptions;
	const mainItems = isAdmin ? adminMainItems : guestMainItems;

	const NavbarItem = (item: Item) => {
		if (item.info?.id === 'darkMode') {
			return <Checkbox checked={isDarkMode}>Dark Mode</Checkbox>;
		}
		if (item.info?.id === 'logout') {
			return <LabelMedium>{item.label}</LabelMedium>;
		}
		return (
			<NavLink to={item.info.url}>
				<LabelMedium>{item.label}</LabelMedium>
			</NavLink>
		);
	};
	const handleItemSelect = (item: Item) => {
		if (item.info.id === 'darkMode') toggleStyleMode();
		if (item.info?.onClick) item.info.onClick();
		if (item.info?.url) navigate(item.info?.url);
	};
	const Title = <NavLink to='/'>{config.appName}</NavLink>;
	return (
		<AppNavBar
			title={Title}
			mainItems={mainItems}
			userItems={userItems}
			onMainItemSelect={handleItemSelect}
			onUserItemSelect={handleItemSelect}
			mapItemToNode={NavbarItem}
			username={displayName}
			usernameSubtitle={!user ? 'Guest' : isAdmin ? 'Admin' : 'Renter'}
			userImgUrl='../../public/images/favicon.ico'
		/>
	);
}
