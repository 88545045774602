import { getDatabase, onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';

export function useQueryValue<T>(
	dbPath: string
): [
	objects: T | null,
	setObjects: React.Dispatch<React.SetStateAction<T | null>>
] {
	const db = getDatabase();
	const [object, setObject] = useState<T | null>(null);
	useEffect(() =>
		onValue(
			ref(db, dbPath),
			(snapshot) => {
				if (object === null && snapshot.exists()) {
					setObject(snapshot.val());
				}
			},
			{
				onlyOnce: true,
			}
		)
	);
	return [object, setObject];
}

export function useQueryArray<T>(
	dbPath: string
): [objects: Array<T>, setObjects: React.Dispatch<React.SetStateAction<T[]>>] {
	const db = getDatabase();
	const [objects, setObjects] = useState<Array<T>>([]);
	useEffect(() =>
		onValue(
			ref(db, dbPath),
			(snapshot) => {
				if (objects.length === 0 && snapshot.exists()) {
					setObjects(Object.values(snapshot.val()));
				}
			},
			{
				onlyOnce: true,
			}
		)
	);
	return [objects, setObjects];
}
