import { ReactNode, useContext, useEffect, useState } from 'react';

import { AuthContext } from './AuthContext';
import { ConfigContext } from '../config/ConfigContext';
import { User } from 'firebase/auth';
import { auth } from '../../firebaseSetup';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const config = useContext(ConfigContext);
	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
			setUser(firebaseUser);
		});

		return unsubscribe;
	}, []);

	const isAdmin =
		user?.email === 'therealworldprojectllc@gmail.com' ||
		user?.email === config.adminEmail;

	return (
		<AuthContext.Provider value={{ user, isAdmin }}>
			{children}
		</AuthContext.Provider>
	);
};
