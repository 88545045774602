import {
	ALIGN,
	HeaderNavigation,
	StyledNavigationItem,
	StyledNavigationList,
} from 'baseui/header-navigation';
import { Button, SHAPE } from 'baseui/button';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { NavLink, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';

import { ConfigContext } from '../../contexts/config/ConfigContext';
import { useStyletron } from 'baseui';

export function Footer() {
	const [css] = useStyletron();
	const config = useContext(ConfigContext);
	const location = useLocation();
	const isBookingScreen = location.pathname.includes('book');
	return (
		<div
			className={css({
				position: 'absolute',
				bottom: 0,
				width: '100%',
			})}>
			<HeaderNavigation>
				<StyledNavigationList $align={ALIGN.left}>
					<div
						className={css({
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						})}>
						<NavLink to='/'>
							<StyledNavigationItem>
								<LabelMedium>{config.appName}</LabelMedium>
							</StyledNavigationItem>
						</NavLink>
					</div>
				</StyledNavigationList>
				<StyledNavigationList $align={ALIGN.center}>
					<div
						className={css({
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						})}>
						<LabelSmall>{config.adminCopywrite}</LabelSmall>
					</div>
				</StyledNavigationList>
				<StyledNavigationList $align={ALIGN.right}>
					{isBookingScreen ? (
						<StyledNavigationItem>
							<div className={css({ paddingRight: '2rem' })}>
								<Button shape={SHAPE.pill} onClick={() => window.scroll(0, 0)}>
									Back to Top
								</Button>
							</div>
						</StyledNavigationItem>
					) : (
						<NavLink to='/book'>
							<StyledNavigationItem>
								<div className={css({ paddingRight: '2rem' })}>
									<Button shape={SHAPE.pill}>Book Now</Button>
								</div>
							</StyledNavigationItem>
						</NavLink>
					)}
				</StyledNavigationList>
			</HeaderNavigation>
		</div>
	);
}
