import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { HeadingMedium } from 'baseui/typography';
import { Input } from 'baseui/input';
import { toaster } from 'baseui/toast';
import { updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export function ProfilePage() {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const [displayName, setDisplayName] = useState(user?.displayName ?? '');

	useEffect(() => {
		const temp = user?.displayName;
		if (!!temp) setDisplayName(temp);
	}, [user?.displayName]);
	useEffect(() => {
		if (!user) navigate('/signIn');
	}, [navigate, user]);

	const handleSubmit = () => {
		if (!user || displayName === '') {
			toaster.negative('Failed to update profile.');
			return;
		}
		updateProfile(user, { displayName })
			.then(() => {
				toaster.positive('Successfully updated profile.');
			})
			.catch(() => {
				toaster.negative('Failed to update profile.');
			});
	};

	return (
		<>
			<HeadingMedium>Profile</HeadingMedium>
			<FormControl label='Email'>
				<Input
					onChange={(e) => null}
					value={user?.email ?? ''}
					disabled={true}
				/>
			</FormControl>
			<FormControl label='Display Name'>
				<Input
					onChange={(e) => setDisplayName(e.target.value)}
					value={displayName}
				/>
			</FormControl>
			<Button onClick={handleSubmit}>Update</Button>
		</>
	);
}
