export const dateIsDisabled = (date: Date) => {
	const timeCheck = new Date();
	if (date.getTime() < timeCheck.getTime()) return true;
	timeCheck.setFullYear(timeCheck.getFullYear() + 1);
	if (date.getTime() > timeCheck.getTime()) return true;
	return false;
};

export const getContactRequestPath = (uid: string, timestamp: number) =>
	`contact_requests/${uid}/${JSON.stringify(timestamp)}`;

export const getContactRequestForUserPath = (uid: string) =>
	`contact_requests/${uid}`;

export const getNumVisitorsOptions = (maxNumVisitors: number) => {
	const res = [];
	for (let i = 1; i < maxNumVisitors + 1; i++) {
		res.push({ id: i, label: i });
	}
	return res;
};
