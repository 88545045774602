import { Book } from '../book/Book';
import React from 'react';
import { toaster } from 'baseui/toast';
import { useBookState } from '../../hooks/useBookState';
import { useNavigate } from 'react-router-dom';

export function HomePageGuest() {
	const navigate = useNavigate();
	const {
		numVisitors,
		setNumVisitors,
		dates,
		onUpdateDates,
		searchIsDisabled,
	} = useBookState();

	const handleSearch = () => {
		if (searchIsDisabled) {
			toaster.negative('Please select start and end dates to search.');
			return;
		}
		navigate('/book', {
			state: {
				numVisitors: numVisitors[0].id,
				startDate: dates[0],
				endDate: dates[1],
			},
		});
	};
	return (
		<Book
			dates={dates}
			onUpdateDates={onUpdateDates}
			// TODO exclude dates that are not available for any homes
			excludeDates={[]}
			numVisitors={numVisitors}
			setNumVisitors={setNumVisitors}
			onSearch={handleSearch}
			searchIsDisabled={searchIsDisabled}
		/>
	);
}
