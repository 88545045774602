import React, { useContext, useState } from 'react';

import { Block } from 'baseui/block';
import { Book } from './Book';
import { ConfigContext } from '../../contexts/config/ConfigContext';
import { Listing } from '../listing/Listing';
import { ParagraphMedium } from 'baseui/typography';
import { toaster } from 'baseui/toast';
import { useBookState } from '../../hooks/useBookState';

export function BookingPageGuest() {
	const config = useContext(ConfigContext);
	const {
		numVisitors,
		setNumVisitors,
		dates,
		onUpdateDates,
		searchIsDisabled,
	} = useBookState();
	const [filteredListings, setFilteredListings] = useState(
		config.listings.filter(
			(listing) => listing.maxNumVisitors > numVisitors[0].id - 1
		)
	);
	const handleSearch = () => {
		// TODO error handling
		if (dates.length < 2) {
			toaster.negative('Please select your start and end dates.');
			return;
		}
		if (!numVisitors || numVisitors.length === 0) {
			toaster.negative('Please select a number of visitors.');
			return;
		}
		setFilteredListings(
			config.listings.filter(
				(listing) => listing.maxNumVisitors > numVisitors[0].id - 1
			)
		);
	};

	return (
		<>
			<Book
				dates={dates}
				onUpdateDates={onUpdateDates}
				// TODO exclude dates that are not available for any homes
				excludeDates={[]}
				numVisitors={numVisitors}
				setNumVisitors={setNumVisitors}
				onSearch={handleSearch}
				searchIsDisabled={searchIsDisabled}
			/>
			{filteredListings.length === 0 && (
				<Block marginTop='2rem'>
					<ParagraphMedium>
						No listings match this search criteria
					</ParagraphMedium>
				</Block>
			)}
			{filteredListings.map((listing) => (
				<Listing listing={listing} />
			))}
		</>
	);
}
