export const TODAY = new Date();

export const START_DATE_DEFAULT = TODAY;
const DURATION_DEFAULT = 2;
export const END_DATE_DEFAULT = new Date().setDate(
	START_DATE_DEFAULT.getDate() + DURATION_DEFAULT
) as unknown as Date;

export const DEFAULT_NUM_VISITORS = 1;

export enum StyleMode {
	LIGHT,
	DARK,
}

export const MAX_NUM_VISITORS = 8;
