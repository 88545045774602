import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyDdmKaFBt4r7x-M-tfTwhiVKmyXMhEFR-Y',
	authDomain: 'the-real-world-project-llc.firebaseapp.com',
	projectId: 'the-real-world-project-llc',
	storageBucket: 'the-real-world-project-llc.appspot.com',
	messagingSenderId: '177885685848',
	appId: '1:177885685848:web:7a285e2823d503bc09022d',
	measurementId: 'G-KCDRJ08FBT',
	databaseURL:
		'https://the-real-world-project-llc-default-rtdb.firebaseio.com/',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
