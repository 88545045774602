import './index.css';

import {
	AuthPage,
	BookingPageAdmin,
	BookingPageGuest,
	ContactPageAdmin,
	ContactPageGuest,
	HomePageAdmin,
	HomePageGuest,
	ListingPageAdmin,
	ListingPageGuest,
	ProfilePage,
	UnderConstructionScreen,
} from './components';
import { PLACEMENT, ToasterContainer } from 'baseui/toast';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/auth/AuthProvider';
import { ConfigProvider } from './contexts/config/ConfigProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ScreenContainer } from './components/common';
import { StyleProvider } from './contexts/style/StyleProvider';

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ScreenContainer
				adminPage={<HomePageAdmin />}
				guestPage={<HomePageGuest />}
			/>
		),
	},
	{
		path: '/register',
		element: (
			<ScreenContainer
				adminPage={<HomePageAdmin />}
				guestPage={<AuthPage authMode='register' />}
			/>
		),
	},
	{
		path: '/login',
		element: (
			<ScreenContainer
				adminPage={<HomePageAdmin />}
				guestPage={<AuthPage authMode='login' />}
			/>
		),
	},
	{
		path: '/resetPassword',
		element: (
			<ScreenContainer
				adminPage={<HomePageAdmin />}
				guestPage={<AuthPage authMode='resetPassword' />}
			/>
		),
	},
	{
		path: '/profile',
		element: (
			<ScreenContainer
				adminPage={<ProfilePage />}
				guestPage={<ProfilePage />}
			/>
		),
	},
	{
		path: '/contact',
		element: (
			<ScreenContainer
				adminPage={<ContactPageAdmin />}
				guestPage={<ContactPageGuest />}
			/>
		),
	},
	{
		path: '/listing',
		element: (
			<ScreenContainer
				adminPage={<ListingPageAdmin />}
				guestPage={<ListingPageGuest />}
			/>
		),
	},
	{
		path: '/book',
		element: (
			<ScreenContainer
				adminPage={<BookingPageAdmin />}
				guestPage={<BookingPageGuest />}
			/>
		),
	},
	{
		path: '/book/:id',
		element: <UnderConstructionScreen />,
	},
	{
		path: '*',
		element: <UnderConstructionScreen />,
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<ConfigProvider>
			<StyleProvider>
				<AuthProvider>
					<ToasterContainer
						placement={PLACEMENT.bottomRight}
						autoHideDuration={10000}>
						<RouterProvider router={router} />
						{/* <App /> */}
					</ToasterContainer>
				</AuthProvider>
			</StyleProvider>
		</ConfigProvider>
	</React.StrictMode>
);
