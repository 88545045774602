import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import {
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from 'firebase/auth';

import { AuthContext } from '../../contexts/auth/AuthContext';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { StyledLink } from 'baseui/link';
import { auth } from '../../firebaseSetup';
import { toaster } from 'baseui/toast';
import { useStyletron } from 'styletron-react';

type AuthMode = 'login' | 'register' | 'resetPassword';

const authModeData = {
	login: {
		name: 'Login',
		switchTextPrepend: "Don't have an account?",
		switchTextPostpend: 'Register',
		switchPath: '/register',
	},
	register: {
		name: 'Register',
		switchTextPrepend: 'Already have an account?',
		switchTextPostpend: 'Login',
		switchPath: '/login',
	},
	resetPassword: {
		name: 'Reset Password',
		switchTextPrepend: 'Remembered your password?',
		switchTextPostpend: 'Login',
		switchPath: '/login',
	},
};

export function AuthPage({ authMode }: { authMode: AuthMode }) {
	const [css] = useStyletron();
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const { name, switchTextPostpend, switchTextPrepend, switchPath } =
		authModeData[authMode];

	const resetForm = () => {
		setEmail('');
		setPassword('');
	};
	const handleSubmit = () => {
		switch (authMode) {
			case 'login':
				signInWithEmailAndPassword(auth, email, password)
					.then(() => toaster.positive('Successfully signed in.'))
					.catch(() =>
						toaster.negative(
							'Failed to login. Please enter a valid email and password.'
						)
					);
				resetForm();
				break;
			case 'register':
				createUserWithEmailAndPassword(auth, email, password)
					.then(() => toaster.positive('Successfully created account.'))
					.catch(() =>
						toaster.negative(
							'Failed to create account. Please enter a valid email and password.'
						)
					);
				resetForm();
				break;
			case 'resetPassword':
				sendPasswordResetEmail(auth, email)
					.then(() => {
						toaster.positive(`Reset password email sent to ${email}.`);
						resetForm();
						navigate('/');
					})
					.catch(() =>
						toaster.positive(`Failed to reset the password for ${email}.`)
					);
				break;
		}
	};

	useEffect(() => {
		if (!!user) navigate('/');
	}, [navigate, user]);

	return (
		<>
			<HeadingMedium>{name}</HeadingMedium>
			<ParagraphMedium>
				{switchTextPrepend}{' '}
				<Link to={switchPath} className='text-thm'>
					<StyledLink>{switchTextPostpend}</StyledLink>
				</Link>
			</ParagraphMedium>
			<FormControl label='Email'>
				<Input onChange={(e) => setEmail(e.target.value)} value={email} />
			</FormControl>
			{authMode !== 'resetPassword' && (
				<FormControl label='Password'>
					<Input
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						type='password'
					/>
				</FormControl>
			)}
			<Button onClick={handleSubmit}>{name}</Button>
			{authMode === 'login' && (
				<ParagraphMedium className={css({ marginTop: '1rem' })}>
					Forgot your password? Click{' '}
					<NavLink to={'/resetPassword'}>
						<StyledLink>Here</StyledLink>
					</NavLink>
					.
				</ParagraphMedium>
			)}
		</>
	);
}
