import { DEFAULT_NUM_VISITORS } from '../constants';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export function useBookState() {
	const location = useLocation();
	const defaultNumVisitors =
		location?.state?.numVisitors ?? DEFAULT_NUM_VISITORS;
	const [numVisitors, setNumVisitors] = useState([
		{
			id: defaultNumVisitors,
			label: defaultNumVisitors,
		},
	]);
	const [dates, setDates] = useState<Array<Date>>([
		location?.state?.startDate,
		location?.state?.endDate,
	]);
	const searchIsDisabled = dates.length < 2 || !dates[0] || !dates[1];
	const onUpdateDates = ({
		date,
	}: {
		date: Date | Date[] | null | undefined;
		// @ts-ignore
	}) => setDates(date);
	return {
		numVisitors,
		setNumVisitors,
		dates,
		onUpdateDates,
		searchIsDisabled,
	};
}
