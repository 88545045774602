import { Card, StyledBody } from 'baseui/card';
import React, { useState } from 'react';

import { Block } from 'baseui/block';
import { ContactRequestT } from '../../types';
import { Input } from 'baseui/input';
import { ParagraphMedium } from 'baseui/typography';
import { Tag } from 'baseui/tag';

export function ContactRequest({
	contactRequest,
	userName,
	customerSupportName,
}: {
	contactRequest: ContactRequestT;
	userName: string;
	customerSupportName: string;
}) {
	const date = new Date(contactRequest.ts);
	const [text, setText] = useState('');
	const handleEnterPress = () => {
		console.log('asd Enter');
	};
	return (
		<Card
			key={contactRequest.ts}
			overrides={{
				Root: {
					style: { margin: '2rem', width: '100%' },
				},
			}}>
			<StyledBody>
				<Block
					width='100%'
					display='flex'
					flexDirection='row'
					justifyContent='space-between'>
					<ParagraphMedium>Created {date.toDateString()}</ParagraphMedium>
					{contactRequest.state === 'open' ? (
						<Tag closeable={false}>Open</Tag>
					) : (
						<Tag closeable={false}>Closed</Tag>
					)}
				</Block>
				{contactRequest.messages.map((message) => (
					<ParagraphMedium>
						{message.sender === 'user' ? userName : customerSupportName}:{' '}
						{message.text}
					</ParagraphMedium>
				))}
				<Input
					value={text}
					onChange={(e) => setText(e.target.value)}
					type='textarea'
					onKeyPress={(event) => event.key === 'Enter' && handleEnterPress()}
				/>
			</StyledBody>
		</Card>
	);
}
