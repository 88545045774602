import React, { ReactNode, useContext, useState } from 'react';

import { BaseProvider } from 'baseui';
import { ConfigContext } from '../config/ConfigContext';
import { StyleContext } from './StyleContext';
import { StyleMode } from '../../constants';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';

const engine = new Styletron();

export function StyleProvider({ children }: { children: ReactNode }) {
	const config = useContext(ConfigContext);
	const [styleMode, setStyleMode] = useState<StyleMode>(StyleMode.LIGHT);
	const toggleStyleMode = () => {
		if (styleMode === StyleMode.LIGHT) setStyleMode(StyleMode.DARK);
		else setStyleMode(StyleMode.LIGHT);
	};
	const isLightMode = styleMode === StyleMode.LIGHT;
	const isDarkMode = styleMode === StyleMode.DARK;
	return (
		<StyletronProvider value={engine}>
			<StyleContext.Provider
				value={{ isLightMode, isDarkMode, toggleStyleMode }}>
				<BaseProvider
					theme={isLightMode ? config.lightTheme : config.darkTheme}>
					{children}
				</BaseProvider>
			</StyleContext.Provider>
		</StyletronProvider>
	);
}
