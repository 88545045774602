import { Calendar, Datepicker } from 'baseui/datepicker';
import React, { useContext } from 'react';

import { Button } from 'baseui/button';
import { ConfigContext } from '../../contexts/config/ConfigContext';
import { FormControl } from 'baseui/form-control';
import { HeadingMedium } from 'baseui/typography';
import { Select } from 'baseui/select';
import { SelectOptionT } from '../../types';
import { TODAY } from '../../constants';
import { getNumVisitorsOptions } from '../../utils';
import { useStyletron } from 'styletron-react';

export function Book({
	dates,
	onUpdateDates,
	excludeDates,
	numVisitors,
	setNumVisitors,
	onSearch,
	searchIsDisabled,
}: {
	dates: Array<Date>;
	onUpdateDates: ({ date }: { date: Date | Date[] | null | undefined }) => void;
	excludeDates: Array<Date>;
	numVisitors: Array<SelectOptionT>;
	setNumVisitors: React.Dispatch<
		React.SetStateAction<
			{
				id: number;
				label: number;
			}[]
		>
	>;
	onSearch: () => void;
	searchIsDisabled: boolean;
}) {
	const [css] = useStyletron();
	const config = useContext(ConfigContext);
	const numVisitorsOptions = getNumVisitorsOptions(config.maxNumVisitors);
	return (
		<>
			<HeadingMedium className={css({ marginBottom: '1rem' })}>
				{config.bookingSearchCTA}
			</HeadingMedium>
			<Calendar
				initialState={{ value: dates }}
				// @ts-ignore
				onChange={onUpdateDates}
				value={dates}
				minDate={TODAY}
				excludeDates={excludeDates}
				range
			/>
			<Datepicker
				aria-label='Select a date'
				clearable={true}
				initialState={{ value: dates }}
				// @ts-ignore
				onChange={onUpdateDates}
				value={dates}
				minDate={TODAY}
				excludeDates={excludeDates}
				autoFocusCalendar
				range
				peekNextMonth
			/>
			<FormControl label={'Number of Guests'}>
				<Select
					options={numVisitorsOptions}
					// @ts-ignore
					value={numVisitors}
					// @ts-ignore
					onChange={(params) => setNumVisitors(params.value)}
					clearable={false}
					// @ts-ignore
					onFocus={() => navigator.virtualKeyboard.hide()}
				/>
			</FormControl>
			<Button
				overrides={{ Root: { style: { width: '100%' } } }}
				onClick={onSearch}
				disabled={searchIsDisabled}>
				Search
			</Button>
		</>
	);
}
