import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Accordion, Panel } from 'baseui/accordion';
import {
	Card,
	StyledAction,
	StyledBody,
	StyledHeaderImage,
	StyledTitle,
} from 'baseui/card';
import { KIND, SIZE, Tag } from 'baseui/tag';
import React, { useCallback } from 'react';

import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Carousel } from 'react-responsive-carousel';
import { ListingT } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';

export function Listing({ listing }: { listing: ListingT }) {
	const [css] = useStyletron();
	const navigate = useNavigate();
	const handleNavigate = useCallback(
		(listingId: string) => navigate(`/book/${listingId}`),
		[navigate]
	);
	const info = [listing.address];
	return (
		<div className={css({ padding: '1rem', width: '100%' })}>
			<Card overrides={{ Root: { style: { width: '100%' } } }}>
				<Block display='flex' flexDirection='row'>
					<Block width='100%' display='flex' flexDirection='column'>
						<StyledTitle>{listing.name}</StyledTitle>
						<StyledBody>{listing.description}</StyledBody>
					</Block>
					<Block
						width='100%'
						display='flex'
						flexDirection='row'
						justifyContent='flex-end'>
						<Tag closeable={false} kind={KIND.positive} size={SIZE.large}>
							{`$${listing.price} / ${listing.paymentInterval}`}
						</Tag>
					</Block>
				</Block>
				<Tag kind={'blue'} closeable={false}>
					{`${listing.numBeds} Bed / ${listing.numBaths} Bath`}
				</Tag>
				<Tag kind={'blue'} closeable={false}>
					{listing.squareFootage}
				</Tag>
				{listing.tags.map((tag) => (
					<Tag kind={tag.kind} closeable={false}>
						{tag.message}
					</Tag>
				))}
				<Block>
					<Carousel infiniteLoop showIndicators={false}>
						{listing.imageUrls.map((imageUrl) => (
							<div key={imageUrl}>
								<StyledHeaderImage src={imageUrl} />
							</div>
						))}
					</Carousel>
				</Block>
				<Block paddingTop='1rem' paddingBottom='1rem'>
					<Accordion>
						<Panel title='More Information'>
							{info.map((i) => (
								<StyledBody>{i}</StyledBody>
							))}
						</Panel>
					</Accordion>
				</Block>
				<StyledAction>
					<Button
						overrides={{ BaseButton: { style: { width: '100%' } } }}
						onClick={() => handleNavigate(listing.id)}>
						Book
					</Button>
				</StyledAction>
			</Card>
		</div>
	);
}
