import React, { useContext } from 'react';

import Alert from 'baseui/icon/alert';
import { ParagraphMedium } from 'baseui/typography';
import { ScreenContainer } from '.';
import { StyleContext } from '../../contexts/style/StyleContext';
import { useStyletron } from 'baseui';

export function UnderConstructionScreen() {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [css, theme] = useStyletron();
	const { isLightMode } = useContext(StyleContext);
	const UnderConstructionPage = () => (
		<>
			<Alert
				size={theme.sizing.scale1600}
				color={isLightMode ? theme.colors.black : theme.colors.white}
			/>
			<ParagraphMedium>This site is under construction.</ParagraphMedium>
			<ParagraphMedium>Please check back soon!</ParagraphMedium>
		</>
	);
	return (
		<ScreenContainer
			adminPage={<UnderConstructionPage />}
			guestPage={<UnderConstructionPage />}
		/>
	);
}
